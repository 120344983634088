import React from "react";
import { motion } from "framer-motion";

const SlideEffect = ({ children, positioninqueue, startposition }) => {
  return (
    <div>
      <motion.div
        initial={{
          opacity: 0,
          x: startposition,
        }}
        whileInView={{
          opacity: 1,
          x: 0,
        }}
        transition={{ duration: 1.5, delay: 0.3 * positioninqueue }}
      >
        {children}
      </motion.div>
    </div>
  );
};

export default SlideEffect;
