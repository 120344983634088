import React, { useEffect } from "react";

const RedirectMainPage = () => {
    useEffect(() => {
        window.location.href = "/UndercoverInvaders";
    }, []);
    return <div></div>;
};

export default RedirectMainPage;
