import React, { useEffect, useState } from "react";
import { Col, Container, Row } from "react-bootstrap";

import collectionMetada from "../assets/_metadata.json";
import CollectionItem from "../components/CollectionItem";
import CollectionFilters from "../components/CollectionFilters";

const SecCollection = () => {
  useEffect(() => { }, []);
  // Function to filter elements based on multiple trait_type criteria
  function filterElementsByTraits(data, traits) {
    return data.filter((item) => {
      return traits.every((trait) => {
        return item.attributes.some((attribute) => {
          return (
            attribute.trait_type === trait.type &&
            attribute.value === trait.value
          );
        });
      });
    });
  }

  const [filterCriteria, setFilterCriteria] = useState([]);
  const filteredElements = filterElementsByTraits(
    collectionMetada,
    filterCriteria
  );

  function handleChildCallback(childData) {
    setFilterCriteria(childData);
  }

  return (
    <section className="collection-container">
      <div className="collection-area">
        <Container>
          <h2 className="section-title">
            Explore the complete collection !
            <div className="big-divider"></div>
          </h2>
          <CollectionFilters sendDataToParent={handleChildCallback} />
          <p className="comment centered-text">
            Click on your favourite Invaders to see its characteristics !
          </p>
          <Row>
            {filteredElements.length === 0 ?
              <div className="centered-div filters-error-message">
                <p>There is no element corresponding to your research. Please reset filters and try again.</p>
              </div> :
              filteredElements.map((item, index) => (
                <Col key={index} xs={4} sm={3} md={4} lg={3}>
                  <CollectionItem item={item} />
                </Col>
              ))}
          </Row>
        </Container>
      </div>
    </section>
  );
};

export default SecCollection;
