import React, { useEffect, useRef, useState } from "react";
import NavbarSlideEffect from "./effects/NavbarSlideEffect";
import { NavLink } from "react-router-dom";

const NavigationBar = () => {
    const [showNavbar, setShowNavbar] = useState(false);
    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    const [scrolled, setScrolled] = useState(false);
    window.addEventListener("scroll", function () {
        if (window.scrollY > 0) {
            setScrolled(true);
        } else {
            setScrolled(false);
        }
    });

    function useOutsideAlerter(ref) {
        useEffect(() => {
            function handleClickOutside(event) {
                if (ref.current && !ref.current.contains(event.target)) {
                    setShowNavbar(false);
                }
            }
            document.addEventListener("mousedown", handleClickOutside);
            return () => {
                document.removeEventListener("mousedown", handleClickOutside);
            };
        }, [ref]);
    }
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef);

    const navbarLinks = (
        <div className="navbar-links">
            <div className="navbar-element">
                <NavLink
                    to="https://x.com/InvadersLabs"
                    target="_blank"
                    className="navbar-link bi bi-twitter"
                    onClick={() => { setShowNavbar(false) }}

                ></NavLink>
            </div>
            <div className="navbar-element">
                <NavLink
                    to="https://oncyber.io/invaderslabs"
                    target="_blank"
                    onClick={() => { setShowNavbar(false) }}
                >
                    Showroom
                </NavLink>
            </div>
            <div className="navbar-element">
                <NavLink
                    to="https://magiceden.io/marketplace/undercover_invaders?activeTab=items"
                    target="_blank"
                    onClick={() => { setShowNavbar(false) }}
                >
                    Magic Eden
                </NavLink>
            </div>
            <div className="navbar-element">
                <NavLink
                    to="/UndercoverInvaders/Collection"
                    onClick={() => { setShowNavbar(false) }}
                >
                    Collection
                </NavLink>
            </div>
            <div className="navbar-element"            >
                <NavLink
                    to="/UndercoverInvaders/Project"
                    onClick={() => { setShowNavbar(false) }}
                >
                    Project
                </NavLink>
            </div>
            <div className="navbar-element"            >
                <NavLink
                    to="/UndercoverInvaders/faq"
                    onClick={() => { setShowNavbar(false) }}
                >
                    F.A.Q.
                </NavLink>
            </div>
        </div>
    )
    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };
        window.addEventListener('resize', handleResize);
        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);
    return (
        <div ref={wrapperRef}>
            <div className={windowWidth < 768 ? "il-logo il-logo-small" : "il-logo"}>
                <NavLink
                    to="/UndercoverInvaders"
                >
                    <img
                        src="/assets/Logo Complet J.png"
                        alt="il-logo"
                        onClick={() => { setShowNavbar(false) }} />
                </NavLink>
            </div>
            {
                windowWidth < 768 ? (
                    <div className={scrolled ? "navbar-small navbar-visible" : "navbar-small"}>
                        {showNavbar ?
                            <NavbarSlideEffect>
                                <div className={windowWidth > 768 ? "" : "navbar-sided"}>
                                    <div
                                        className="bi bi-list navbar-dropdown-icon"
                                        onClick={() => { setShowNavbar(!showNavbar) }}
                                    >
                                    </div>
                                    {navbarLinks}
                                </div>
                            </NavbarSlideEffect>
                            : <div
                                className="bi bi-list navbar-dropdown-icon font-color1"
                                onClick={() => { setShowNavbar(!showNavbar) }}
                            >
                            </div>
                        }
                    </div>
                ) : (<div className={scrolled ? "navbar-big navbar-visible" : "navbar-big"}>
                    {navbarLinks}
                </div>
                )
            }
        </div>


    );
};

export default NavigationBar;
