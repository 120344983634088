import React, { useEffect } from 'react';
import SecProjectTeam from '../sections/SecProjectTeam';
import SecProjectRoadmap from '../sections/SecProjectRoadmap';
import Footer from '../components/Footer';

const Project = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <SecProjectRoadmap />
            <SecProjectTeam />
            <Footer />
        </div>
    );
};

export default Project;