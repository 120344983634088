import React from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import SlideEffect from '../components/effects/SlideEffect';

const SecProjectTeam = () => {
    return (
        <section className='projectteam-container display'>
            <h2 className="section-title">
                Project Team
                <div className="big-divider"></div>
            </h2>
            <div>
                <Container>
                    <Row>
                        <Col sm={12} md={6}>
                            <SlideEffect positioninqueue={1} startposition={"-100%"}>
                                <div className='rounded-border-box mid-shadow teammember-container'>
                                    <div className='teammember-information'>
                                        <Row>
                                            <Col className="teammember-img-container">
                                                <img
                                                    className="teammember-img"
                                                    src={`/assets/Nawaken_UIProfilePicture.png`}
                                                    alt={"nawaken-img"}
                                                />
                                            </Col>
                                            <Col className='teammember-description'>
                                                <div className='teammember-name'>Nawaken</div>
                                                <div className='teammember-role'>Software Engineer</div>
                                                <div className="small-divider"></div>
                                                <p className='teammember-description-text'>
                                                    Former Investment Engineer with a passion for crypto, I'm now allocating my technical skills to the NFT space!
                                                </p>
                                                <a
                                                    href='https://x.com/NawakenX'
                                                    target="_blank"
                                                    className="bi bi-twitter">
                                                </a>
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </SlideEffect>
                        </Col>
                        <Col sm={12} md={6}>
                            <SlideEffect positioninqueue={1} startposition={"100%"}>
                                <div className='rounded-border-box mid-shadow teammember-container'>
                                    <div className='teammember-information'>
                                        <Row>
                                            <Col className='teammember-description'>
                                                <div className='teammember-name'>Screept</div>
                                                <div className='teammember-role'>Lead Designer</div>
                                                <div className="small-divider"></div>
                                                <p className='teammember-description-text'>
                                                    Former legal advisor in the banking sector, with a pen in my heart. I'm now creating NFT with love and passion !
                                                </p>
                                                <a
                                                    href='https://x.com/ScreeptX'
                                                    target="_blank"
                                                    className="bi bi-twitter">
                                                </a>
                                            </Col>
                                            <Col className="teammember-img-container">
                                                <img
                                                    className="teammember-img"
                                                    src={`/assets/Screept_UIProfilePicture.png`}
                                                    alt={"screept-img"}
                                                />
                                            </Col>
                                        </Row>
                                    </div>
                                </div>
                            </SlideEffect>
                        </Col>
                    </Row>
                </Container>
            </div>
        </section>
    );
};

export default SecProjectTeam;