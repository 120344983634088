import collectionStats from "../assets/_collectionStats.json";

import React, { useState } from "react";
import { Modal } from "react-bootstrap";
import FadeInEffect from "./effects/FadeInEffect";

const CollectionItem = ({ item }) => {
  const [showModal, setShowModal] = useState(false);
  return (
    <div
      className="collection-item small-shadow"
      onClick={() => {
        setShowModal(!showModal);
      }}
    >
      {console.log(item)}
      <div className="collection-img-container">
        <img
          className="collection-img"
          src={`/assets/undercover-invaders/small/${item.image}`}
          alt={"ImageUi-" + item.edition}
        />
      </div>
      <div className="infos">
        <div className="item-name">{item.name}</div>
      </div>
      <Modal
        centered
        show={showModal}
        scrollable={true}
        onHide={() => {
          setShowModal(false);
        }}
      >
        <FadeInEffect>
          <Modal.Header closeButton bsPrefix=""></Modal.Header>
          <Modal.Body>
            <h3 className="item-name-big font-color3">{item.name}
              <div className="big-divider"></div></h3>
            <img
              className="collection-img round-border small-shadow"
              src={`/assets/undercover-invaders/${item.image}`}
              alt={"ImageUi-" + item.edition}
            />
          </Modal.Body>

          <Modal.Footer className="collection-item-footer centered small-shadow">
            <div className="attributes-container">
              <div>
                {item.attributes.map((attribute, index) =>
                  index <= 3 && attribute.trait_type !== "Body" ? (
                    <div key={index} className="attribute-box small-shadow">
                      <div className="attribute-type">{attribute.trait_type}</div>
                      <div className="attribute-value">
                        {attribute.value}
                        <span className="italic">
                          {" - "}
                          {collectionStats[attribute.trait_type][attribute.value]}
                          {"%"}
                        </span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )
                )}
              </div>
              <div>
                {item.attributes.map((attribute, index) =>
                  index > 3 && attribute.trait_type !== "Body" ? (
                    <div key={index} className="attribute-box small-shadow">
                      <div className="attribute-type">{attribute.trait_type}</div>
                      <div className="attribute-value">
                        {attribute.value}
                        <span className="italic">
                          {" - "}
                          {collectionStats[attribute.trait_type][attribute.value]}
                          {"%"}
                        </span>
                      </div>
                    </div>
                  ) : (
                    ""
                  )
                )}
              </div>
            </div>
            <div className="additional-infos">{/* Is Minted ? Owner ? etc .. */}</div>
          </Modal.Footer>
        </FadeInEffect>
      </Modal>
    </div>
  );
};

export default CollectionItem;
