import React, { useEffect } from 'react';
import SecFAQ from '../sections/SecFAQ';
import Footer from '../components/Footer';

const FAQ = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <SecFAQ />
            <Footer />
        </div>
    );
};

export default FAQ;