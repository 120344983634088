import React, { useEffect } from 'react';
import SecCollection from '../sections/SecCollection';
import Footer from '../components/Footer';
import FadeInEffect from '../components/effects/FadeInEffect';

const Collection = () => {
    useEffect(() => {
        window.scrollTo(0, 0);
    }, []);
    return (
        <div>
            <FadeInEffect>
                <SecCollection />
            </FadeInEffect>
            <Footer />
        </div>
    );
};

export default Collection;