import React from "react";
import { motion } from "framer-motion";

const NavbarSlideEffect = ({ children }) => {
  return (
    <div>
      <motion.div
        initial={{
          opacity: 0,
          x: "-50%",
        }}
        whileInView={{
          opacity: 1,
          x: 0,
        }}
        transition={{ duration: 0.5, delay: 0 }}
      >
        {children}
      </motion.div>
    </div>
  );
};

export default NavbarSlideEffect;
