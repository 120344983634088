import React, { useEffect, useState } from "react";

const SecLanding = () => {
  return (
    <section className="landing-container">
      <div className="content">
        <div className="landing-title-container">
          <h1>UNDERCOVER INVADERS</h1>
          <h2>They are ready to take over the world !</h2>
        </div>
        <div className="big-divider"></div>
        <div className="landing-image-container">
          <img
            className=""
            src={`assets/ui-transparent-font/0.png`}
            alt={"ImageUitransparent"}
          />
        </div>
        <div className="landing-projectdescription-container">
          <p>
            "These Aliens are coming from another galaxy to take over the world.
            They are trying to look incognito, but their only model was Reality TV
            Actors ...
          </p>
          <p>Ho shit !"</p>
        </div>
      </div>

    </section>
  );
};

export default SecLanding;
