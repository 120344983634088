import 'bootstrap/dist/css/bootstrap.min.css';

import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import RedirectMainPage from './components/RedirectMainPage';
import ParticleBg from './components/ParticleBg';
import NavigationBar from './components/NavigationBar';
import Collection from './pages/Collection';
import Project from './pages/Project';
import FAQ from './pages/FAQ';

function App() {
  return (
    <div className="App">
      <BrowserRouter>
        <ParticleBg />
        <NavigationBar />
        <Routes>
          {/* <Route path="*" element={<Home />} /> */}
          <Route path="/UndercoverInvaders" element={<Home />} />
          <Route path="/UndercoverInvaders/Collection" element={<Collection />} />
          <Route path="/UndercoverInvaders/Project" element={<Project />} />
          <Route path="/UndercoverInvaders/faq" element={<FAQ />} />
          <Route path="*" element={
            <RedirectMainPage />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}
export default App;
