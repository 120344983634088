import React, { useEffect, useState } from 'react';

const Footer = () => {
    const [scrollToTopVisible, setScrollToTopVisible] = useState(false)
    const [isPageBottom, setIsPageBottom] = useState(false)

    // Show button when page is scrolled down
    const scrollMonitoring = () => {
        if (window.scrollY > 300) {
            setScrollToTopVisible(true);
            if (window.scrollY + window.innerHeight >= document.documentElement.scrollHeight - 70) {
                setIsPageBottom(true)
            } else {
                setIsPageBottom(false)
            }
        } else {
            setScrollToTopVisible(false);
        }
    };

    const scrollToTop = () => {
        window.scrollTo({
            top: 0,
            behavior: 'smooth'
        });
    };

    useEffect(() => {
        window.addEventListener('scroll', scrollMonitoring);
        return () => window.removeEventListener('scroll', scrollMonitoring);
    }, []);

    return (
        <footer>
            <div className="footer-container mid-shadow">
                {scrollToTopVisible &&
                    <div
                        onClick={() => scrollToTop()}
                        className={isPageBottom ? 'button-backtotop-footer' : 'button-backtotop'}
                    >
                        <div className='bi bi-chevron-double-up'></div>
                    </div>
                }
                <div className='footer-socialmedia flex-container'>
                    <div>
                        <a
                            href='https://x.com/InvadersLabs'
                            target="_blank"
                            className="bi bi-twitter">

                        </a>
                    </div>
                    <div>
                        <a
                            href='https://www.instagram.com/invaderslabs/'
                            target="_blank"
                            className="bi bi-instagram">

                        </a>
                    </div>
                    <div>
                        <a
                            href='https://discord.gg/FxM5TfZ9Kc'
                            target="_blank"
                            className="bi bi-discord">
                        </a>
                    </div>
                </div>
                <div className='footer-disclaimer'>
                    © 2024 Copyright Invaders Labs. All rights reserved.
                </div>
            </div>
        </footer>
    );
};

export default Footer;