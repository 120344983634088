import React from 'react';
import { Container } from 'react-bootstrap';
import faqItems from "../assets/faq-items.json";
import FadeInEffect from '../components/effects/FadeInEffect';

const SecFAQ = () => {
    return (
        <section className='faq-container'>
            <Container>
                <h2 className="section-title">
                    Frequently Asked Questions
                    <div className="big-divider"></div>
                </h2>
                {faqItems.map((item, index) => {
                    return (
                        <div key={index}>
                            <FadeInEffect>
                                <div className="faq-item-container">
                                    <h3 className='faq-question'>{index + 1 + ". " + item.question}</h3>
                                    <p className='faq-response'>{item.answer}</p>
                                </div>
                                <div className="small-divider"></div>
                            </FadeInEffect>
                        </div>
                    )
                })}
            </Container>
        </section>
    );
};

export default SecFAQ;