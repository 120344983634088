import React, { useEffect, useState } from "react";
import collectionStats from "../assets/_collectionStats.json";
import { Col, Container, Form, Row } from "react-bootstrap";

const CollectionFilters = ({ sendDataToParent }) => {
  const [selectedBackground, setSelectedBackground] = useState();
  const [selectedClothes, setSelectedClothes] = useState();
  const [selectedEyes, setSelectedEyes] = useState();
  const [selectedMouth, setSelectedMouth] = useState();
  const [selectedHat, setSelectedHat] = useState();

  const [listDisplayedFilters, setListDisplayedFilters] = useState([])

  const [showFilters, setShowFilters] = useState(false);

  function handleResetFilters() {
    setSelectedBackground();
    setSelectedClothes();
    setSelectedEyes();
    setSelectedMouth();
    setSelectedHat();
  }

  let filterCriteria = [
    selectedBackground
      ? { type: "Background", value: selectedBackground }
      : {
        type: "Body",
        value: "Body",
      } /* Need to replace this body shit to be more clean */,
    selectedClothes
      ? { type: "Clothes", value: selectedClothes }
      : { type: "Body", value: "Body" },
    selectedEyes
      ? { type: "Eyes", value: selectedEyes }
      : { type: "Body", value: "Body" },
    selectedMouth
      ? { type: "Mouth", value: selectedMouth }
      : { type: "Body", value: "Body" },
    selectedHat
      ? { type: "Hat", value: selectedHat }
      : { type: "Body", value: "Body" },
  ];

  function handleTraitCheckbox(trait, traitValue) {
    switch (trait) {
      case "Background":
        return traitValue === selectedBackground;
      case "Clothes":
        return traitValue === selectedClothes;
      case "Eyes":
        return traitValue === selectedEyes;
      case "Mouth":
        return traitValue === selectedMouth;
      case "Hat":
        return traitValue === selectedHat;
      default:
        return false;
    }
  }

  function handleTraitCheckboxChange(trait, traitValue) {
    switch (trait) {
      case "Background":
        if (traitValue === selectedBackground) {
          setSelectedBackground();
        } else {
          setSelectedBackground(traitValue);
        }
        break;
      case "Clothes":
        if (traitValue === selectedClothes) {
          setSelectedClothes();
        } else {
          setSelectedClothes(traitValue);
        }
        break;
      case "Eyes":
        if (traitValue === selectedEyes) {
          setSelectedEyes();
        } else {
          setSelectedEyes(traitValue);
        }

        break;
      case "Mouth":
        if (traitValue === selectedMouth) {
          setSelectedMouth();
        } else {
          setSelectedMouth(traitValue);
        }
        break;
      case "Hat":
        if (traitValue === selectedHat) {
          setSelectedHat();
        } else {
          setSelectedHat(traitValue);
        }
        break;
      default:
        return false;
    }
  }

  function handleFiltersDisplay(trait) {
    if (listDisplayedFilters.includes(trait)) {
      setListDisplayedFilters(listDisplayedFilters.filter((item, i) => item !== trait))
    } else {
      setListDisplayedFilters([...listDisplayedFilters, trait])
    }
  }
  useEffect(() => {
    sendDataToParent(filterCriteria);
  }, [
    selectedBackground,
    selectedClothes,
    selectedEyes,
    selectedMouth,
    selectedHat,
  ]);

  return (
    <div className="collection-filters">
      <div className="filter-area">
        <Container>
          <div className="collection-filters-buttons">
            <div
              id="resetfilter-button"
              className="squared-shorttext-button"
              onClick={() => handleResetFilters()}
            >
              RESET FILTERS
            </div>
            <span
              className="squared-flex-button"
              onClick={() => setShowFilters(!showFilters)}
            >
              <i className="bi bi-search"></i>
            </span>
          </div>
          {showFilters ? (
            <Row>
              {["Background", "Clothes", "Eyes", "Mouth", "Hat"].map(
                (trait, index) => (
                  <Col
                    key={index}
                    xs={index === 4 ? 12 : 6}
                    md
                  >
                    <div className="filter-area-traittype small-shadow">
                      <div
                        className="filter-area-title"
                        onClick={() => { handleFiltersDisplay(trait) }}
                      >
                        {trait}
                        <span
                          className={
                            listDisplayedFilters.includes(trait) ?
                              "bi bi-caret-up-fill filter-dd-arrow" :
                              "bi bi-caret-down-fill filter-dd-arrow"
                          }
                        ></span>
                      </div>
                      {listDisplayedFilters.includes(trait) ? (
                        <div>
                          <div className="small-divider"></div>
                          <div className="radio-container">
                            {Object.entries(collectionStats[trait]).map(
                              ([traitValue, traitCount], index) => (
                                <div
                                  className={
                                    handleTraitCheckbox(trait, traitValue)
                                      ? "radio-container-select selected-radio"
                                      : "radio-container-select"
                                  }
                                  key={index}
                                  onClick={() => {
                                    handleTraitCheckboxChange(trait, traitValue);
                                  }}
                                >
                                  {traitValue}
                                </div>
                              )
                            )}
                          </div></div>
                      ) : ""}

                    </div>

                  </Col>
                )
              )}
            </Row>
          ) : (
            ""
          )}
        </Container>
      </div>
    </div >
  );
};

export default CollectionFilters;
